// Sass tils
@import '../Styles/Colours';
@import '../Styles/Sizes';

.loading-popup-content {
    display: flex;
    flex-direction: column;
    background-color: $color-white;
    // min-width: 400px;
    // min-height: 300px;
    border-radius: 5px;
}

.loading-popup-overlay {
    background-color: rgba(0,0,0,0.7);
}

.loading-popup-main {
    flex: 1;
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.loading-popup-text {
    color: $color-grey-4;
    font-weight: 400;
}

.loading-popup-spinner {
    font-size: 2rem;
    color: $color-fuelservice-blue;
}
