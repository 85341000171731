// Sass tils
@import '../Styles/Colours';
@import '../Styles/Sizes';

.card {
    display: flex;
    flex-direction: column;
    background-color: $color-white;
    border: 1px solid #cfd8dc;
}

.card-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 20px;
    z-index: 10;
    gap: 20px;
}

.card-title {
    display: flex;
    align-items: flex-end;
    font-size: 28px;
    line-height: 28px;;
    color: $color-fuelservice-blue;
}

.card-center-items {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex: 2;
    align-items: center;
    gap: 28px;
}

.card-header-search {
    width: 200px;
    border: none;
    border-bottom: 1px solid $color-fuelservice-blue;
    font-size: 16px;
    appearance: none;
    margin-bottom: 0px;
}

.card-csv-export-button {
    color: $color-white;
    background-color: $color-fuelservice-blue;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    cursor: pointer;
    font-size: 12px;
    line-height: 10px;
}

.card-csv-export-button:hover {
    background-color: darken($color-fuelservice-blue,10%);
}

.card-csv-export-button:hover:active {
    background-color: darken($color-fuelservice-blue,20%);
}

.card-main-chart-table {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.card-main-chart-table > div {
    min-width: 50%;
}

.card-main {
    padding: 20px;
}

.card-footer {
    background-color: $color-off-white;
    padding: 20px;
}

@media only screen and (max-width: 600px) {
    .card-csv-export-button {
        display: none;
    }

    .card-header {
        flex-direction: column;
    }

    .card-center-items {
        justify-content: flex-start !important;
    }
}

.card-month-select {
    border: none;
    font-size: 16px;
}