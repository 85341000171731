// Sass tils
@import '../../Styles/Colours';
@import '../../Styles/Sizes';

.mobile-rotate-content {
    display: flex;
    flex-direction: column;
    background-color: $color-white;
    max-width: 90vw;
    // border-radius: 25px;
    // padding: 50px;
}

.mobile-rotate-text {
    font-size: 20px;
    margin-bottom: 30px;
    text-align: center;
}

.mobile-rotate-overlay {
    background-color: rgba(0,0,0,0.7);
}

