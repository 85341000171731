@import '../Styles/Colours';

.widget {
    padding: 20px;
    background-color: $color-white;
    display: flex;
    flex-direction: row;
    gap: 16px;
    border: 1px solid #cfd8dc;
    border-radius: 4px;
    width: auto;
    flex: 1;
    min-width: 250px;
}

.widget-icon {
    padding: 16px;
    color: $color-white;
    height: 24px;
    width: 24px;
}

.widget-text-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: center;
}

.widget-value {
    font-size: 18px;
    font-weight: 600;
}

.widget-title {
    color: $color-grey-6;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
}