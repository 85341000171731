html, body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Open Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

/* html,
body {
  height: 100%;
} */

/* These styles disable body scrolling if you are using <ScrollView> */
/* body {
  overflow: hidden;
} */

/* These styles make the root element full-height */
#root {
  /* display: flex; */
  /* height: 100%; */
}
/* 
input[text] {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}

button {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
} */

@media only screen and (max-width: 600px) {
  .desktop-only {
    display: none;
  }
}

@media only screen and (min-width: 600px) {
  .mobile-only {
    display: none;
  }
}

