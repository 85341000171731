.dashboard {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.dashboard-row {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}