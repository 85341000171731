.feedback {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
}

.feedback-rating-wait {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.feedback-rating-wait > div {
    flex: 1;
}