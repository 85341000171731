@import '../Styles/Colours';

.wmy-button {
    display: inline;
    padding: 6px;
    color: $color-grey-6;
    cursor: pointer;
}

.wmy-selected {
    display: inline;
    padding: 6px;
    background-color: $color-grey-c;
    color: $color-grey-1;
}