.issues {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 20px;
    width: 100%;
}

.issues-card {
    flex: 1;
}

.issues-main-content {
    flex: 1 1 auto;
}