.station-table-brand {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 500px;
}
.station-table-brand img {
    width: 24px;
    height: 24px;
    margin-right: 8px;
}