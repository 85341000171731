// Sass tils
@import '../Styles/Colours';
@import '../Styles/Sizes';

.body {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgb(33,166,239);
    background: radial-gradient(circle, rgba(33,166,239,1) 0%, rgba(0,96,171,1) 57%, rgba(0,76,152,1) 100%);
    color: $color-grey-2;
}

.logo {
    width: 300px;
    height: auto;
}

.container {
    background-color: $color-white;
}

.form {
    display: flex;
    flex-direction: row;
}

@media only screen and (max-width: 800px) {
    .form {
        flex-direction: column;
    }
}

.left {
    padding: 50px 50px 50px 50px;
    display: flex;
    flex-direction: column;
    background-color: $color-fuelservice-blue;
    color: $color-white;
    align-items: center;
}

.right {
    padding: 50px 50px 50px 50px;
    display: flex;
    flex-direction: column;
}

.rightEmail {
    padding: 50px 50px 50px 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.rightEmailText {
    padding-left: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 1.5rem;
}

.rightLoggingIn {
    padding: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 1.5rem;
}

.rightLoggingInText {
    margin-bottom: 30px;
}

.emailInput {
    appearance: none;
    font-size: 30px;
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
    border: 2px solid rgba(0,76,152,1);
}

.loginButton {
    margin-top: 20px;
    font-size: 30px;
    background-color: rgba(0,76,152,1);
    border-radius: 10px;
    color: $color-white;
    border: 2px solid rgba(0,76,152,1);
    padding: 10px;
    cursor: pointer;
}

.loginButton:disabled {
    color: $color-grey-4;
    background-color: $color-white;
    border: 2px solid $color-grey-4;
}

.errorMessage {
    color: red;
    font-weight: 600;
}

.saveAuthPopupContainer {
    
}

.saveAuthPopup-content {
    display: flex;
    flex-direction: column;
    background-color: $color-white;
}

.saveAuthPopup-overlay {
    background-color: rgba(0,0,0,0.7);
}

.saveAuthPopup-arrow {
    
}

.saveAuthPopupHeader {
    padding: 14px;
    color: $color-white;
    background-color: $color-fuelservice-blue;
    font-size: 1.5rem;
    text-align: center;
}

.saveAuthPopupMain {
    padding: 30px;
    font-size: 1.2rem;
    text-align: center;
}

.saveAuthPopupMainHint {
    margin-top: 10px;
    font-size: 1rem;
    margin-bottom: 30px;
    color: $color-grey-4;
}

.saveAuthPopupButtons {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.saveAuthPopupButtons button {
    border-radius: 20px;
    background-color: $color-white;
    padding: 10px;
    cursor: pointer;
    font-size: 1.2rem;
    margin-left: 20px;
    margin-right: 20px;
    min-width: 100px;
}

.saveAuthPopupButtons button:hover {
    font-weight: 600;
}

.yesButton {
    border: 2px solid $color-green;
    color: $color-green;
}

.noButton {
    border: 2px solid $color-error-red-dark;
    color: $color-error-red-dark;
}