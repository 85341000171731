// Sass tils
@import '../Styles/Colours';
@import '../Styles/Sizes';

.blue-button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    color: $color-white;
    background-color: $color-fuelservice-blue;
    border-radius: .25rem;
    cursor: pointer;
    font-size: 16px;
}

.blue-button:hover {
    background-color: darken($color-fuelservice-blue,10%);
}

.blue-button:hover:active {
    background-color: darken($color-fuelservice-blue,20%);
}

.blue-button-disabled {
    display: inline;
    padding: 8px 16px;
    color: $color-white;
    background-color: $color-grey-8;
    border-radius: .25rem;
    font-size: 16px;
}

.white-button {
    display: inline;
    padding: 8px 16px;
    color: $color-grey-2;
    background-color: $color-white;
    border-radius: .25rem;
    cursor: pointer;
    font-size: 16px;
}

.white-button:hover {
    background-color: darken($color-white,10%);
}

.white-button:hover:active {
    background-color: darken($color-white,20%);
}

.white-button-disabled {
    display: inline;
    padding: 8px 16px;
    color: $color-grey-6;
    background-color: $color-white;
    border-radius: .25rem;
    font-size: 16px;
}