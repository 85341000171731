$color-menu-background: #263238;
$color-fuelservice-blue: #3498db;

$color-green: #4dbd74;
$color-red: #e13838;
$color-yellow: #f8cb00;
$color-light-blue: #63c2de;

$color-white: #ffffff;
$color-off-white: #eceff1;

$color-grey-1: #101010;
$color-grey-2: #202020;
$color-grey-3: #303030;
$color-grey-4: #404040;
$color-grey-5: #505050;
$color-grey-6: #606060;
$color-grey-7: #707070;
$color-grey-8: #808080;
$color-grey-9: #909090;
$color-grey-a: #a0a0a0;
$color-grey-b: #b0b0b0;
$color-grey-c: #c0c0c0;
$color-grey-d: #d0d0d0;
$color-grey-e: #e0e0e0;

$color-error-red-light: #f2dede;
$color-error-red-dark: #a94442;
