// Sass tils
@import '../Styles/Colours';
@import '../Styles/Sizes'; 

.header {
    position: fixed;
    display: flex;
    flex-direction: row;
    height: 54px;
    width: 100%;
    align-items: center;
    z-index: 999;
    background-color: $color-white;
    font-size: 20px;
    justify-content: space-between;
}

.header-start-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 54px;
}

.header-menu-button {
    color: $color-menu-background;
    padding: 15px;
    cursor: pointer;
}

.header-menu-button-icon {
    font-size: 20px;
}

.header-logo-desktop {
    width: 200px;
    height: auto;
}

.header-logo-mobile {
    height: 30px;
    width: 30px;
}

.header-end-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.header-logout-icon {
    cursor: pointer;
    color: $color-grey-8;
    margin-right: 10px;
}