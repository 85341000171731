// Sass tils
@import '../Styles/Colours';
@import '../Styles/Sizes'; 


.main-container {
  height: 100vh;
  max-height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
}

.main-center {
  display: flex;
  flex-direction: row;
  flex: 1;
}

.main-content {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.sub-content {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #e4e5e6;
  padding: 20px;
}

.header-space {
  min-height: 55px;
  width: 100%;
}