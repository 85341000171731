// Sass tils
@import '../Styles/Colours';
@import '../Styles/Sizes';

.ReactVirtualized__Grid.ReactVirtualized__Table__Grid {
  outline: none;
}

.ReactVirtualized__Grid.ReactVirtualized__Table__Grid::-webkit-scrollbar {
  width: 10px;
  border-radius: 5px;
}

/* Track */
.ReactVirtualized__Grid.ReactVirtualized__Table__Grid::-webkit-scrollbar-track {
  background-color: #e4e5e6; 
}

/* Handle */
.ReactVirtualized__Grid.ReactVirtualized__Table__Grid::-webkit-scrollbar-thumb {
  background-color: $color-fuelservice-blue; 
  border-radius: 2px;
}

/* Handle on hover */
.ReactVirtualized__Grid.ReactVirtualized__Table__Grid::-webkit-scrollbar-thumb:hover {
  background-color: darken($color-fuelservice-blue,10%); 
}

.ReactVirtualized__Table__headerRow {
  font-weight: 500;
  font-size: 12px;
  color: $color-grey-1;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.ReactVirtualized__Table__row {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  color: $color-grey-1;
  font-weight: 300;
}

.ReactVirtualized__Table__headerTruncatedText {
  display: inline-block;
  max-width: 100%;
}

.ReactVirtualized__Table__headerColumn,
.ReactVirtualized__Table__rowColumn {
  margin-right: 10px;
  min-width: 0px;
}

.ReactVirtualized__Table__headerColumn {
  display: flex;
  align-items: center;
}

.ReactVirtualized__Table__rowColumn {
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ReactVirtualized__Table__headerColumn:first-of-type,
.ReactVirtualized__Table__rowColumn:first-of-type {
  margin-left: 10px;
}
.ReactVirtualized__Table__sortableHeaderColumn {
  cursor: pointer;
  outline: none;
}

.ReactVirtualized__Table__sortableHeaderIconContainer {
  display: flex;
  align-items: center;
}
.ReactVirtualized__Table__sortableHeaderIcon {
  flex: 0 0 24px;
  height: 1em;
  width: 1em;
  fill: currentColor;
}

.visible-column {
    overflow: visible !important;
}
