.sessions-history-card-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    gap: 20px;
}

@media only screen and (max-width: 600px) {
    .sessions-history-card-footer {
        flex-direction: column;
        background-color: white;
        margin: -20px;
        margin-top: -80px;
        padding: 30px;
    }
}

.sessions-history-card-footer-item

.sessions-history-card-footer-item-title {
    text-align: center;
    font-weight: 400;
}

.sessions-history-card-footer-item-text {
    text-align: center;
    margin-top: 8px;
    font-weight: 500;
}

.sessions-history-card-footer-item-percentage {
    height: 4px;
    margin-top: 8px;
}

.sessions-history-date-type {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
}