// Sass tils
@import '../Styles/Colours';
@import '../Styles/Sizes';

.table-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}

.tbl-header {
  background-color: rgba(255, 255, 255, 0.3);
}

.tbl-content {
  overflow: auto;
  max-height: 500px;
  margin-top: 0px;
  border: 1px solid rgba(255, 255, 255, 0.3);
}

tbody {
  height: 200px;
  max-height: 200px;
  overflow-x: auto;
}

th {
  position: sticky; top: 0; z-index: 1;
  padding: 20px 15px 20px 10px;
  text-align: left;
  font-weight: 500;
  font-size: 12px;
  color: $color-grey-1;
  text-transform: uppercase;
  background-color: $color-white;
}

tr:hover {
  background-color: $color-grey-e;
}

td {
  padding: 10px;
  vertical-align: middle;
  font-weight: 300;
  font-size: 14px;
  color: $color-grey-1;
  border-bottom: solid 1px rgba(255, 255, 255, 0.1);
}

tfoot {
  position: sticky; bottom: 0; z-index: 1;
  padding: 20px 15px;
  font-size: 12px;
  color: $color-grey-1;
  background-color: $color-white;
}

tfoot td {
  font-weight: 400;
}

.tbl-content::-webkit-scrollbar {
  width: 10px;
  border-radius: 5px;
}

/* Track */
.tbl-content::-webkit-scrollbar-track {
  background-color: #e4e5e6; 
}

/* Handle */
.tbl-content::-webkit-scrollbar-thumb {
  background-color: $color-fuelservice-blue; 
  border-radius: 2px;
}

/* Handle on hover */
.tbl-content::-webkit-scrollbar-thumb:hover {
  background-color: darken($color-fuelservice-blue,10%); 
}