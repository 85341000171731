// Sass tils
@import '../Styles/Colours';
@import '../Styles/Sizes';

.menu {
    background-color: $color-menu-background;
    transition: 0.5s;
    transition-timing-function: ease;
}

.menu-group {
    padding: .75rem 1rem;
    font-size: 11px;
    font-weight: 600;
    color: #cfd8dc;
    text-transform: uppercase;
}

.menu-item {
    display: flex;
    flex-direction: row;
    padding: .75rem 1rem;
    color: $color-white;
    text-decoration: none;
    background: transparent;
    cursor: pointer;
    white-space: nowrap;
    font-size: 15px;
}

.menu-item:hover {
    background-color: $color-fuelservice-blue;
    color: $color-white !important;
}

.menu-item-icon {
    min-width: 44px;
    color: #b0bec5;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.menu-item:hover .menu-item-icon {
    color: $color-white;
}

.menu-item-active {
    background-color: #304047;
}

.menu-item-active .menu-item-icon {
    color: $color-fuelservice-blue;
}