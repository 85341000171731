@import '../Styles/Colours';

.issues-widget {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
    border: 3px solid $color-error-red-dark;
    border-radius: 5px;;
    background-color: $color-white;
    padding: 20px;
    cursor: pointer;
}

.issues-widget-title {
    color: $color-error-red-dark;
    font-size: 28px;
    flex: 1;
    align-self: center;
    min-width: 250px;
}

.issues-widget .widget {
    border: none;
    padding: 0px;
}